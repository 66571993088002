// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'receiver',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'shop',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'product_detail',
    label: '',
    Object: 'value',
    width: '400'
  }, {
    prop: 'shipping_detail',
    label: '',
    Object: 'value',
    width: '400'
  }, {
    prop: 'orders',
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'status',
    back: true,
    label: '',
    Object: 'value',
    width: '180'
  }, {
    prop: 'meet_moq',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'admin_remarks',
    label: '',
    Object: 'value',
    width: '120'
  }],
  orderViewItem: [{
    prop: 'status',
    back: true
  }, {
    prop: 'type'
  }, {
    prop: 'total_amount'
  }, {
    prop: 'moq'
  }, {
    prop: 'created_at',
    date: true,
    format: ['YYYY年mm月dd日 HH:MM', 'YYYY-mm-dd HH:MM']
  }, {
    prop: 'admin_remarks'
  }],
  receiverViewItem: [{
    prop: 'customer_name'
  }, {
    prop: 'role'
  }, {
    prop: 'email'
  }, {
    prop: 'nickname'
  }, {
    prop: 'contact_number'
  }, {
    prop: 'customer_id'
  }],
  deliveryViewItem: [{
    prop: 'delivery_time'
  }, {
    prop: 'receiver_name'
  }, {
    prop: 'detail_address'
  }, {
    prop: 'receiver_phone_number'
  }],
  tableDataViewItem: [{
    prop: 'image',
    img: true,
    label: '',
    width: '40'
  }, {
    prop: 'shop_item',
    label: '',
    Object: 'value',
    width: '100'
  }, {
    prop: 'shop_item_stock',
    label: '',
    Object: 'value',
    width: '100'
  }, {
    prop: 'preferences',
    label: '',
    Object: 'value',
    width: '40'
  }, {
    prop: 'sku',
    label: '',
    Object: 'value',
    width: '40'
  }, {
    prop: 'count',
    label: '',
    width: '20',
    Object: 'value'
  }],
  tableUserDataItem: [{
    prop: 'id',
    label: '',
    width: '100',
    Object: 'value'
  }, {
    prop: 'user_nickname',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'shop',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'order_detail',
    label: '',
    Object: 'value',
    width: '400'
  }, {
    prop: 'delivery_detail',
    label: '',
    Object: 'value',
    width: '400'
  }, {
    prop: 'status',
    back: true,
    label: '',
    Object: 'value',
    width: '120'
  }]
}
